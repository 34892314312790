import { axiosApiInstance } from "@/plugins/axios";
import moment from "moment";

function get_training_sessions() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "/",
    method: "get",
  })
    .then((res) => {
      this.items = this.alltrainings = res.data;
      // console.log(res.status);
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
    });
  // axios.get
}
function get_training_categories() {
  this.loading = true;

  axiosApiInstance({
    url: this.url + "/categories",
    method: "get",
  })
    .then((res) => {
      this.available_categories = this.categories_for_edit= res.data;
      this.filter_categories = [{ value: null, text: "All Categories" }].concat(res.data);

      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
    });

}

function format_date(date) {
  return date ? moment(date, "YYYY-MM-DD").format("ll") : "-";
}

function filter_training() {
  this.category
    ? (this.items = this.alltrainings.filter(
        (item) => item.category == this.category
      ))
    : (this.items = this.alltrainings);
}

function view_training(training) {
  this.$router.push({
    name: "training-session",
    params: {
      training_id: training.id,
    },
  });
}
function get_districts() {
  axiosApiInstance
    .get(this.baseUrl + `farmers/districts`)
    .then((result) => {
      this.districts=[
        { value: null, text: "Select District" },
      ]
      const res = result.data.data.sort((a, b) =>
        a.district.localeCompare(b.district)
      );
      res.map((each) => {
        this.districts.push(each.district);
      });
    })
    .catch((error) => {
      this.$bvToast.toast(error, {
        title: "Connection Failed",
        toaster: "b-toaster-top-right",
        variant: "danger",
        solid: true,
      });
    });
}

export {
  get_training_sessions,
  get_training_categories,
  format_date,
  filter_training,
  view_training,
  get_districts,
};
