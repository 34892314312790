import { axiosApiInst } from "@/plugins/axios";
import moment from "moment";

function update_training() {
  this.loading=true;
  axiosApiInst({
    url:this.url+'/'+this.training.id,
    method: "put",
    data:this.training,
  })
    .then((res) => {
      console.log(res.status);
      this.loading = false;
      this.$emit('Successful');
      this.$bvToast.toast(res.data.message, {
        title: "Successfully Edited!",
        toaster: "b-toaster-bottom-center",
        variant: "success",
        solid: true,
      });
      this.$bvModal.hide('edit-training');

    })
    .catch((error) => {
      this.loading = false;
      this.$emit('Successful');
      this.$bvToast.toast(error, {
        title: "Failed to Save!",
        toaster: "b-toaster-bottom-center",
        variant: "danger",
        solid: true,
      });
    });
}
function format_num(num){
  return num?Number(num).toLocaleString():'-'
}

function add_trainer(){
  let word = this.capitalize(this.trainer);
  if(word.length>0){
    this.training.trainers = this.update_list(this.training.trainers, word);
  }
  this.trainer = "";
}
function capitalize(word) {
  return word
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    .trim();
}
function update_list(list, word) {
  list.push(word);
  list = [...new Set(list)];
  return list;
}

function remove_duplicates(list) {
  return [...new Set(list)];
}
function remove_trainer(index) {
  this.training.trainers.splice(index, 1);
  this.updated;
}
function close() {
  this.$bvModal.hide("edit-training");
}

function time_format(time) {
  return moment(time,"YYYY-MM-DD HH:mm:ss").format("LL");
}
export { 
  update_training, 
  time_format,
  format_num,
  add_trainer,capitalize,update_list,remove_duplicates,remove_trainer,
  close
};
