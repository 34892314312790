import { axiosApiInst } from "@/plugins/axios";

function select_farmer(checked, trainee_id) {
  if (checked) {
    this.newly_selected = [...this.newly_selected,trainee_id];
  } else {
    this.newly_selected = this.newly_selected.filter(el=>el!=trainee_id);
  }
}

function submit_trainees() {
  // const data = this.form;
  this.loading = true;
  axiosApiInst({
    url: this.url + "/" + this.training_id + "/users",
    method: "post",
    data:this.newly_selected,
  })
    .then((res) => {
      this.loading = false;
      this.success = true;
      this.$emit('Successful');
      this.$bvToast.toast(res.data.message, {
        title: "Successfully Added!",
        toaster: "b-toaster-bottom-center",
        variant: "success",
        solid: true,
      });
      this.$bvModal.hide("add-trainees");
    })
    .catch((error) => {
      this.loading = false;
      this.$emit('Successful');
      this.$bvToast.toast(
        "Error Saving->" + error + ". Contact offtakersupport@ezyagric.com",
        {
          title: "Failed to Save!",
          toaster: "b-toaster-bottom-center",
          variant: "danger",
          solid: true,
        }
      );
    });
}


export { select_farmer, submit_trainees};
