<template>
  <b-modal
    :id="modal"
    title="Update Training"
    hide-footer
    size="lg"
    @show="training = current_training"
    no-close-on-esc
    no-close-on-backdrop
  >
    <training-form
      :loading="loading"
      :training="training"
      :districts="districts"
      :categories="categories"
      :submit_action="submit_action"
      :modal="modal"
      @Submit="update_training"
    >
    </training-form>
  </b-modal>
</template>
<script>
import { time_format, update_training } from "./actions.js";
import TrainingForm from "@/modules/training/components/training_form/TrainingForm.vue";

export default {
  components: { TrainingForm },
  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    districts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    current_training: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        "/training"
      );
    },
  },
  data() {
    return {
      loading: false,
      success: false,
      error: false,
      currency: "UGX. ",
      trainer: "",
      training: this.current_training,
      modal: "edit-training",
      submit_action: "Update Training",
    };
  },
  methods: {
    time_format,
    update_training,
  },
  mounted() {},
};
</script>
<style scoped>
@font-face {
  font-family: "password";
  font-style: normal;
  font-weight: 400;
  src: url(https://jsbin-user-assets.s3.amazonaws.com/rafaelcastrocouto/password.ttf);
}
.password {
  font-family: "password";
}
.badge-primary {
  background-color: #3f7bb6 !important;
}
.small-button {
  width: 0;
}
</style>
