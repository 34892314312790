import { axiosApiInstance } from "@/plugins/axios";
import { get_training_categories, get_districts } from "../trainings/actions";
import {} from "@/modules/training/utils";
import moment from "moment";
function get_training() {
  this.loading = true;
  let training_id = this.$route.params.training_id;
  axiosApiInstance({
    url: this.url + "/" + training_id,
    method: "get",
  })
    .then((res) => {
      this.training = res.data[0];
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function get_trainees() {
  this.loading = true;
  let training_id = this.$route.params.training_id;
  axiosApiInstance({
    url: this.url + "/" + training_id + "/users",
    method: "get",
  })
    .then((res) => {
      this.attendees = res.data;
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function get_non_selected() {
  this.loading = true;
  let training_id = this.$route.params.training_id;
  axiosApiInstance({
    url: this.url + "/" + training_id + "/users/unselected/",
    method: "get",
  })
    .then((res) => {
      this.farmers = res.data;
      this.loading = false;
    })
    .catch((error) => {
      console.log(error);
      this.loading = false;
    });
  // axios.get
}

function format_date(date) {
  return date ? moment(date, "YYYY-MM-DD HH:mm:ss").format("LL") : "-";
}
export {
  get_training,
  format_date,
  get_trainees,
  get_training_categories,
  get_districts,
  get_non_selected,
};
