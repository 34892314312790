import { required,minValue } from "vuelidate/lib/validators";
let training = {
    category:{
      required
    },
    start_date:{
      required
    },
    topic: {
      required
    },
    district:{
      required
    },
    duration:{
      required,
      minValue:minValue(1)
    },
    target:{
      minValue:minValue(1)
    },
    location:{
      required
    }
  };
export {training}