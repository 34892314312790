import {axiosApiInstance,axiosApiInst} from "../../../../../../plugins/axios";
const BASE_URL=(project_id,training_id) => process.env.VUE_APP_BACKEND + '/api/v3/'+project_id+'/training'+"/"+training_id
async function getAll(project_id,training_id){
    const res = await axiosApiInstance(
        {
            url:BASE_URL(project_id,training_id)+"/users"+"/unselected/:agents",

        })
    return res
}
/**
 * 
 * @param {number} project_id 
 * @param {number} training_id 
 * @param {Array<string>} users 
 */
async function addAgents(project_id,training_id,users){
    const res = axiosApiInst({
        url:BASE_URL(project_id,training_id)+"/users"+"/:add_agents",
        method:"post",
        data:users
    });
    return res;
}
export {getAll,addAgents}