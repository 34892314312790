<template>
    <b-modal id="add-agents" title="Add agents" hide-footer @show="getAgents" v-model="isShown">
        <div class="d-flex justify-content-between mb-1">
            <b-form-input type="search" v-model="searchName" placeholder="Type Name" style="width:40%;"></b-form-input>
            <b-button variant="outline-primary"><b-icon-plus></b-icon-plus>Add Agent</b-button>
        </div>

        <b-form @submit.prevent="saveAgents">
            <b-table :busy="loading" :items="agents" :fields="fields"
            
            :per-page="perPage" 
            :filter="searchName" 
            :current-page="currentPage">
                <template #table-busy>
                <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                </div>
                </template>
                <template #cell(selected)="data">
                    <b-form-checkbox :value="data.item.trainee_id" name="check-box" v-model="selecteda"></b-form-checkbox>
                </template>
            </b-table>
            <hr/>
            <b-toast v-model="hasErrors" :auto-hide-delay="2000">
                <span class="text-danger">Please select atleast one agent!</span>
            </b-toast>
            <div class="d-flex justify-content-between px-2">
                <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
                ></b-pagination>
                <div>
                    <b-button type="submit" size="md" variant="primary">Save Agents</b-button>
                </div>
            </div>
        </b-form>
    </b-modal>
</template>
<script>
import * as api from "./api";
export default {
    data(){
        return {
            fields:["name","selected"],
            searchName:"",
            selecteda:[],
            loading:true,
            perPage:11,
            agents:[],
            rows:0,
            currentPage:1,
            hasErrors:false,
            isShown:false
        }
    },
    computed:{
        projectId(){
            return this.$store.state.project_id;
        },
        trainingId(){
            return this.$route.params.training_id;
        }
    },
    methods:{
        getAgents(){
            this.loading = true;
            api.getAll(this.projectId,this.trainingId).then(res=>{
                this.agents = res.data;
                this.selecteda = res.data.filter(el=>el['selected']==true).map(el=>el['trainee_id'])
                this.rows = res.headers["x-total-count"];
                this.loading = false;
            });
        },
        saveAgents(){
            if(this.selecteda.length==0){
               this.hasErrors = true;
                return -1;
            }
            api.addAgents(this.projectId,this.trainingId,this.selecteda).then(()=>{
                this.isShown = false;
                this.$emit('Successful');
            });
        }
    }
}
</script>