import {axiosApiInstance} from "../../../../../../plugins/axios";

const BASE_URL = (project_id) => process.env.VUE_APP_BACKEND+"/api/v3/"+project_id+"/training";
/**
 * 
 * @param {number} project_id 
 * @param {number} training_id 
 * @returns 
 */
async function get_non_selected(project_id,training_id,params={}) {
    const res = await axiosApiInstance({
      url: BASE_URL(project_id) + "/" + training_id + "/users/unselected/",
      params
    });
    return res;
}
export {get_non_selected}
