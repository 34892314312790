<template>
  <b-form @submit.prevent="submit">
    <b-container v-if="categories.length > 0">
      <b-form-group
        label-for="category"
        invalid-feedback="Category is required"
      >
        <template #label>
          Category <span class="text-danger">*</span>
        </template>
        <b-form-select
          v-model="$v.training.category.$model"
          :options="categories"
          :disabled="!can('modify', 'training')"
          :state="validateState($v.training.category)"
        >
        </b-form-select>
      </b-form-group>
      <b-form-group label-for="topic" invalid-feedback="Topic is required">
        <template #label> Topic <span class="text-danger">*</span> </template>
        <b-form-input
          v-model="$v.training.topic.$model"
          :state="validateState($v.training.topic)"
          :disabled="!can('modify', 'training')"
        >
        </b-form-input>
      </b-form-group>
      <b-form @submit.prevent="add_trainer">
        <button type="submit" class="hidden small-button"></button>
        <b-form-group
          label-for="trainers"
          description="Press Enter to Add a Trainer"
        >
          <template #label>
            Trainers <span class="text-danger">*</span>
          </template>
          <b-form-input
            v-model="trainer"
            class="border-left-0 border-top-0 border-right-0"
            :disabled="!can('modify', 'training')"
            required
          >
          </b-form-input>
        </b-form-group>
        <div>
          <span
            :key="'pill' + index"
            v-for="(item, index) in training.trainers"
          >
            <b-badge pill variant="primary"
              >{{ item }}&nbsp;
              <button
                class="no-style-button float-right"
                @click="remove_trainer(index)"
              >
                <b-icon-x font-scale="1"></b-icon-x>
              </button> </b-badge
            >&nbsp;
          </span>
        </div>
      </b-form>
      <b-row>
        <b-col>
          <b-form-group
            label-for="district"
            invalid-feedback="District is required"
          >
            <template #label>
              District <span class="text-danger">*</span>
            </template>
            <b-form-select
              v-model="$v.training.district.$model"
              :options="districts"
              :state="validateState($v.training.district)"
              :disabled="!can('modify', 'training')"
            >
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="location"
            invalid-feedback="Location is required"
          >
            <template #label>
              Location <span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model="$v.training.location.$model"
              :state="validateState($v.training.location)"
              :disabled="!can('modify', 'training')"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="image-link"
            description="https://..."
            invalid-feedback="Image link must be a valid url"
          >
            <template #label> Image Link </template>
            <b-form-input
              type="url"
              v-model="training.image_url"
              :disabled="!can('modify', 'training')"
            >
            </b-form-input>
            <template #description></template>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-form-group label-for="start-date">
            <template #label>
              Start Date <span class="text-danger">*</span>
            </template>
            <b-form-datepicker
              v-model="$v.training.start_date.$model"
              :date-format-options="{
                year: 'numeric',
                month: 'short',
              }"
              :state="validateState($v.training.start_date)"
              :disabled="!can('modify', 'training')"
              placeholder="Select"
              value="Selected"
            >
            </b-form-datepicker>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="duration"
            invalid-feedback="Duration cannot be less than 1"
          >
            <template #label>
              Duration (days)<span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.number="$v.training.duration.$model"
              type="number"
              :disabled="!can('modify', 'training')"
              :state="validateState($v.training.duration)"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group
            label-for="target"
            invalid-feedback="Target Participants cannot be less than 1"
          >
            <template #label>
              Target Participants<span class="text-danger">*</span>
            </template>
            <b-form-input
              v-model.number="$v.training.target.$model"
              type="number"
              :state="validateState($v.training.target)"
              :disabled="!can('modify', 'training')"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <div v-show="!loading" class="fluid">
        <b-button @click="close" variant="outline-primary" class="float-left">
          Cancel
        </b-button>
        <b-button
          v-show="!loading"
          :disabled="!can('modify', 'training')"
          type="submit"
          variant="primary"
          class="float-right"
          ><span>{{ submit_action }}</span>
        </b-button>
      </div>
      <b-container v-show="loading">
        <b-spinner label="loading..."></b-spinner>
      </b-container>
    </b-container>
  </b-form>
</template>
<script>
import {
  add_trainer,
  update_list,
  close,
  remove_duplicates,
  remove_trainer,
  capitalize,
  submit,
} from "./actions.js";
import { can } from "@/modules/auth/utils.js";
import { validationMixin } from "vuelidate";
import { training as vtraining } from "@/modules/training/components/training_form/utils.validators";
export default {
  mixins: { validationMixin },
  props: {
    categories: {
      type: Array,
      default: () => {
        return [];
      },
    },
    districts: {
      type: Array,
      default: () => {
        return [];
      },
    },
    training: {
      type: Object,
      default: () => {
        return {};
      },
    },
    submit_action: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: String,
    },
  },
  validations: {
    training: vtraining,
  },
  data() {
    return {
      trainer: "",
    };
  },
  methods: {
    can,
    add_trainer,
    capitalize,
    update_list,
    remove_duplicates,
    remove_trainer,
    close,
    submit,
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
  },
};
</script>
<style scoped>
.password {
  font-family: "password";
}
.badge-primary {
  background-color: #3f7bb6 !important;
}
.small-button {
  width: 0;
}
</style>
