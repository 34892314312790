<template>
  <div>
    <div class="pt-3">
      <p>
        <span>
          <b-iconstack font-scale="1.2">
            <b-icon-circle-fill variant="success"></b-icon-circle-fill>
            <b-icon-calendar4-week
              scale="0.5"
              variant="white"
            ></b-icon-calendar4-week> </b-iconstack
        ></span>
        <span class="pl-2">{{ format_date(training.start_date) }} </span>
      </p>
      <p v-if="training.location">
        <span>
          <b-iconstack font-scale="1.2">
            <b-icon-circle-fill style="color:orange"></b-icon-circle-fill>
            <b-icon-geo-alt-fill
              scale="0.5"
              variant="white"
            ></b-icon-geo-alt-fill> </b-iconstack
        ></span>
        <span class="pl-2">
          {{ training.location }},&nbsp;<b>{{ training.district }}</b>
        </span>
      </p>
      <p>
        <span>
          <b-iconstack font-scale="1.2">
            <b-icon-circle-fill variant="danger"></b-icon-circle-fill>
            <b-icon-arrow-clockwise
              scale="0.5"
              variant="white"
            ></b-icon-arrow-clockwise> </b-iconstack
        ></span>
        <span class="pl-2">{{ training.duration }} Day(s)</span>
      </p>
    </div>

    <b-row class="pt-3">
      <b-col>
        <p class="text-muted">
          <span>
            <b-iconstack font-scale="0.8">
              <b-icon-square variant="success" rotate="45"></b-icon-square>
              <b-icon-square
                scale="0.75"
                variant="success"
                rotate="45"
              ></b-icon-square>
            </b-iconstack>
          </span>
          <span> Category</span>
        </p>
        <p>
          <b>{{ training.category }}</b>
        </p>
      </b-col>
      <b-col>
        <p class="text-muted">
          <span>
            <b-iconstack font-scale="0.9">
              <b-icon-circle variant="success" rotate="45"></b-icon-circle>
              <b-icon-circle
                scale="0.7"
                variant="success"
                rotate="45"
              ></b-icon-circle>
              <b-icon-circle-fill
                scale="0.4"
                variant="success"
                rotate="45"
              ></b-icon-circle-fill>
            </b-iconstack>
          </span>
          <span> Target</span>
        </p>
        <p>
          <b>{{ training.target }}</b>
        </p>
      </b-col>
    </b-row>
    <div class="pt-3">
      <p class="text-muted">Trainers</p>
      <p class="break-word">
        <b
          class="break-word"
          :key="'trainers' + index"
          v-for="(item, index) in training.trainers"
        >
          {{ item
          }}<small 
            v-if="index + 1 < training.trainers.length"
          >,&nbsp;</small>
        </b>
      </p>
    </div>
    <hr />
    <div>
      <p class="text-muted">Total Attendance</p>

      <pie-chart :series="series"></pie-chart>
    </div>
    <div>
      <p class="text-muted">Photos</p>
      <a v-show="training.image_url"  target="_blank" :href="training.image_url" type="submit" class="text-primary no-style-button">
        <u>See Photo Gallery</u>
      </a>
      <b-button v-show="!training.image_url" variant="outline-primary" v-b-modal.edit-training>
        Add image url
      </b-button>
    </div>
  </div>
</template>
<script>
import { can } from "@/modules/auth/utils.js";
import PieChart from "../pie_chart/PieChart.vue";
import { format_date } from "../../actions.js";
export default {
  components: { PieChart },
  props: {
    training: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    series() {
      return [
        this.has_value(this.training.males),
        this.has_value(this.training.females),
      ];
    },
  },
  methods: {
    can,
    format_date,
    has_value(value) {
      return value ? value : 0;
    },
  },
};
</script>
<style scoped>
.break-word {
  word-wrap: break-word;
}
</style>
