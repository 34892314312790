<template>
  <apexchart
    width="280"
    type="donut"
    :options="options"
    :series="series"
  ></apexchart>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: { apexchart: VueApexCharts },
  props: {
    series: {
      type: Array,
      default: () => {
        return [0,0];
      },
    },
  },
  data() {
    return {
      
      options: {
        labels: ["Males", "Females"],
        colors:['#3F7BB6','#4CAB94'],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                  show:true,
                total: {
                  show: true,
                },
              },
            },
          },
        },
      },
      labels: ["males", "females"],
    };
  },
};
</script>
