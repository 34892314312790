<template>
  <b-modal id="delete-training" title="Delete Training" hide-footer>
    <b-contianer v-if="!loading">
      <h6 class="text-muted">
        Are you sure you'd like to delete the training?
      </h6>
      <p>
        <b>{{ training.topic }}</b>
      </p>
      <hr />
      <b-button
        v-if="!loading"
        :disabled="!can('modify', 'training')"
        @click="delete_training"
        variant="outline-danger"
        class="float-left"
      >
        <b-icon-trash></b-icon-trash>
        Remove Training
      </b-button>
    </b-contianer>
    <b-container class="text-center" v-if="loading">
      <span><b>Deleting...&nbsp;&nbsp;&nbsp;</b></span><b-spinner></b-spinner>
    </b-container>
  </b-modal>
</template>
<script>
import { can } from "@/modules/auth/utils.js";
import { delete_training } from "./actions";

export default {
  props: {
    training: {
      type: Object,
      default: () => {
        return {
          topic: "",
        };
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    url() {
      return (
        process.env.VUE_APP_BACKEND +
        "/api/v3/" +
        this.$store.state.project_id +
        "/training"
      );
    },
  },
  methods: {
    delete_training,
    can,
  },
};
</script>
