<template>
  <div>
    <b-navbar>
      <b-navbar-brand class="page-title">
        <b-link class="text-dark" to="/training">TRAINING</b-link>
        <b-icon-chevron-right />
        <a>{{ training.topic ? training.topic : "-" }}</a>
      </b-navbar-brand>
      <b-navbar-nav v-show="!loading" class="ml-auto">
        <div class="pt-2">
        <b-button
          v-if="can('modify', 'training')"
          squared
          variant="outline-primary"
          v-b-modal.edit-training
        >
          <b-icon-gear></b-icon-gear> Edit Training Details
        </b-button>
        </div>

        <div class="pl-3">
          <b-dropdown
            id="add-pps"
            text="Add Participants"
            class="m-md-2"
            variant="success"
            v-show="can('modify', 'training')"
            squared            
          >
            <b-dropdown-item-button v-b-modal.add-trainees>Farmers</b-dropdown-item-button>
            <b-dropdown-item-button v-b-modal.add-agents>Agents</b-dropdown-item-button>
          </b-dropdown>
        </div>
      </b-navbar-nav>
    </b-navbar>
    <div class="page-controls">
      <div class="row m-0">
        <b-col>
          <div class="float-left">
            <div class="d-flex align-items-center">
              <b-form class="ml-auto float-right">
                <b-form-input
                  placeholder="Search Participants..."
                  type="search"
                  class="search"
                  v-model="searchfield"
                ></b-form-input>
              </b-form>
            </div>
          </div>
        </b-col>
        <div class="col-auto">
          <div class="sidebar-width text-right">
            <button class="btn-opt primary" @click="reload">
              <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
              Refresh
            </button>
            <button class="btn-opt primary" v-print="'#AttendeesTable'">
              <b-icon-printer></b-icon-printer> Print
            </button>
            <!-- <button class="btn-opt primary" ><b-icon-book></b-icon-book> Export </button> -->
            <download-excel
              class="btn btn-opt primary"
              :data="attendees"
              type="csv"
            >
              <b-icon-book></b-icon-book> Export
            </download-excel>
          </div>
        </div>
      </div>
    </div>

    <b-row class="mx-0">
      <div class="border-right col-sm-9">
       
        <div class="text-center pt-3" v-show="loading">
          <b-row class="d-flex justify-content-center mb-3">
            <b-spinner
              style="width: 3rem height: 3rem"
              label="Large Spinner"
            ></b-spinner>
          </b-row>
        </div>
        <div class="pt-3" v-show="attendees.length > 0">
          <b-table
            id="AttendeesTable"
            :fields="fields"
            :items="attendees"
            :filter="searchfield"
            sticky-header="60vh"
            head-variant="light"
            @row-clicked="view_user"
            hover
            thead-class="bg-primary-tb text-primary-tb border-primary-tb"
            table-class="w100"
          >
            <template #cell(name)="data">
              <b-avatar icon="person">{{ data.item.name[0] }}</b-avatar>
              {{ data.item.name }}
            </template>
            <template #cell(usertype)="data">
              <usertype-pill :user_type="data.item.user_type">
              </usertype-pill>
            </template>

            <template #cell(amount_paid)="data">
              {{ data.item.amount.toLocaleString() }}
            </template>
          </b-table>
        </div>
        <div class="pt-3" v-show="!loading">
          <b-button v-b-modal.delete-training variant="outline-danger">
            <b-icon-trash></b-icon-trash>
            Remove Training
          </b-button>
        </div>
      </div>

      <training-stats class="col-sm-3" :training="training"></training-stats>
    </b-row>
    <edit-training
      @Successful="reload"
      :categories="available_categories"
      :districts="districts"
      :current_training="training"
    ></edit-training>
    <add-attendees @Successful="reload"> </add-attendees>
    <add-agents @Successful="reload"/>
    <farmer-modal @Success="reload"></farmer-modal>
    <delete-training :training="training"> </delete-training>
  </div>
</template>

<script>
import {
  get_trainees,
  get_training_categories,
  get_districts,
  get_training,
  format_date,
} from "./actions.js";
import EditTraining from "./components/edit_training/EditTraining.vue";
import TrainingStats from "./components/training_stats/TrainingStats.vue";
import AddAttendees from "./components/add_trainees/AddTrainees.vue";
import AddAgents from "./components/add_agents/AddAgents.vue";
import { can } from "@/modules/auth/utils.js";
import { bus } from "@/main.js";
import FarmerModal from "@/components/farmer_modal/Farmer.vue";
import DeleteTraining from "./components/delete_training/DeleteTraining.vue";
import UsertypePill from "@/components/usertype_pill/UsertypePill.vue";

export default {
  components: {
    EditTraining,
    TrainingStats,
    AddAttendees,
    FarmerModal,
    DeleteTraining,
    AddAgents,
    UsertypePill,
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },

    url_prefix() {
      return this.$store.state.url_prefix;
    },
    url() {
      return (
        this.baseUrl +
        this.$store.state.project_id +
        "/training"
      );
    },
  },
  data() {
    return {
      searchfield: "",
      fields: ["name",{ key: "usertype", label: "" }, "age", "gender", "contact"],
      attendees: [],
      isAdding: false,
      training: {},
      sending: [],
      withdrawal: [],
      tax: [],
      loading: false,
      crops: [],
      currency: "UGX",
      money: "money",
      available_categories: [],
      districts: [],
      account: "account",
      include_withdrawal: 0,
      include_tax: 0,

    };
  },
  methods: {
    format_date,
    get_trainees,
    get_training,
    get_districts,
    get_training_categories,
    can,
    reload() {
      this.get_training_categories();
      this.get_districts();
      this.get_training();
      this.get_trainees();
    },
    view_user(item) {
      bus.$emit("farmer-view", {
        name: item.name,
        data: item,
        farmer_id: item.trainee_id,
        tab: "training",
      });
    },
  },
  mounted() {
    this.reload();
  },
};
</script>

<style scoped>
.no-decorate {
  text-decoration: none;
}
.w100 {
  max-width: 100%;
}
.table tbody .edit {
  padding: 8px 2px !important;
}
</style>
