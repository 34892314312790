function add_trainer() {
  let word = this.capitalize(this.trainer);
  if (word.length > 0) {
    this.training.trainers = this.update_list(this.training.trainers, word);
  }
  this.trainer = "";
}
function capitalize(word) {
  return word
    .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()))
    .trim();
}
function update_list(list, word) {
  list.push(word);
  list = [...new Set(list)];
  return list;
}

function remove_duplicates(list) {
  return [...new Set(list)];
}
function remove_trainer(index) {
  this.training.trainers.splice(index, 1);
  this.updated;
}
function close() {
  this.$bvModal.hide(this.modal);
}
function submit(){
  this.$v.training.$touch();
  if (this.$v.training.$anyError)
        return false
  this.$emit("Submit");
}
export {
  add_trainer,
  update_list,
  close,
  remove_duplicates,
  remove_trainer,
  capitalize,
  submit,
};
