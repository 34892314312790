import { axiosApiInstance } from "@/plugins/axios";

function delete_training() {
  this.loading = true;
  axiosApiInstance({
    url: this.url + "/" + this.training.id,
    method: "delete",
  })
    .then((res) => {
      console.log(res.status);
      this.loading = false;
      this.$emit("Successful");
      this.$bvToast.toast(res.data.message, {
        title: "Successfully Deleted!",
        toaster: "b-toaster-bottom-center",
        variant: "success",
        solid: true,
      });
      setTimeout(() => {
        this.$bvModal.hide("edit-training");
        this.$router.push("/training");
      },2000);
      
    })
    .catch((error) => {
      this.loading = false;
      this.$emit("Successful");
      this.$bvToast.toast(error, {
        title: "Failed to Delete!",
        toaster: "b-toaster-bottom-center",
        variant: "danger",
        solid: true,
      });
    });
}

export { delete_training };
