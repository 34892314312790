<template>
  <b-modal
    id="add-trainees"
    title="Add Farmers"
    hide-footer
    no-close-on-backdrop
    @show="getFarmers"
    no-close-on-esc
    >
    <b-form @submit.prevent="submit_trainees">
      <b-container>
        <b-row>
          <b-col>
            <b-form-group>
              <b-form-input
                placeholder="Search Name"
                type="search"
                class="search"
                v-model="searchfield"
                @input="searchFarmers"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              class="float-right"
              variant="outline-primary"
              to="/suppliers/farmers"
            >
              Add New Farmer
            </b-button>
          </b-col>
        </b-row>
        <b-table
          :fields="fields"
          :items="users"
          sticky-header="60vh"
          hover
          ref="addTraineesTable"
          responsive
          thead-class="d-none"
          :busy="isBusy"
          :show-empty="isEmpty"
          empty-text="No results much name"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
          <template #cell(selected)="data">
            <b-form-checkbox
              v-if="data.item.selected"
              v-model="data.item.selected"
              disabled
              checked
            >
            </b-form-checkbox>
            <b-form-checkbox
              v-if="!data.item.selected"
              @change="select_farmer($event, data.item.trainee_id)"
              :checked="newly_selected.includes(data.item.trainee_id)"
            >
            </b-form-checkbox>
          </template>
        </b-table>
        <b-pagination
          v-model="currentPage"
          @input="
            (pg) => {
              currentPage = pg;
              getFarmers();
            }
          "
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
        <hr />
      </b-container>
      <b-row>
        <b-col>
          <b-button class="float-left" variant="outline-primary" close>
            Cancel
          </b-button>
        </b-col>
        <b-col>
          <span class="font-weight-bold text-justify">
            {{ selected_farmers }} Selected
          </span>
        </b-col>
        <b-col v-show="!loading">
          <b-button
            
            class="float-right"
            type="submit"
            :disabled="!can('modify', 'training')"
            variant="primary"
          >
            Add Selected
          </b-button>
        </b-col>
        <b-col v-show="loading">
          <b-button
            class="float-right"
            variant="primary"
          >
            <span>Loading&nbsp;<b-spinner small ></b-spinner></span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>
<script>
import { can } from "@/modules/auth/utils.js";
import { submit_trainees, select_farmer } from "./actions";
import * as api from "./api";
var _ = require("lodash");
export default {
  computed: {
    baseUrl() {
      return process.env.VUE_APP_BACKEND + "/api/v3/";
    },
    projectId() {
      return this.$store.state.project_id;
    },
    url() {
      return (
        this.baseUrl +
        this.$store.state.project_id +
        "/training"
      );
    },
    selected_farmers() {
      return this.newly_selected.length+this.users.filter(el=>el.selected).length;
    },
  },
  data() {
    return {
      users: [],
      fields: ["name", "selected"],
      searchfield: "",
      finder: null,
      perPage: 11,
      rows: 0,
      currentPage: 1,
      isBusy: true,
      isEmpty: false,
      training_id: this.$route.params.training_id,
      loading:false,
      newly_selected:[],
    };
  },
  methods: {
    calcOffset() {
      let tmp = (this.currentPage - 1) * this.perPage;
      return tmp;
    },
    getFarmers() {
      this.isBusy = true;
      this.isEmpty = false;
      api
        .get_non_selected(this.projectId, this.training_id, {
          limit: this.perPage,
          offset: this.calcOffset(),
        })
        .then((res) => {
          this.users = res.data;
          this.rows = res.headers["x-total-count"];
          this.isBusy = false;
        });
    },
    searchFarmers() {
      this.isEmpty = false;
      if (this.finder) {
        this.finder.cancel();
      }
      if(this.searchfield==""){
        this.getFarmers();
        return 0;
      }
      this.finder = _.debounce(() => {
        this.isBusy = true;
        api
          .get_non_selected(this.projectId, this.training_id, {
            farmer_name: this.searchfield,
            limit: this.perPage,
          })
          .then((res) => {
            this.currentPage = 1;
            this.rows = this.perPage;
            this.isBusy = false;
            if (res.status == 204) {
              this.isEmpty = true;
              this.users = [];
              return 0;
            }
            this.users = res.data;
          });
      }, 1200);
      this.finder();
    },
    select_farmer,
    submit_trainees,
    can,
  },
};
</script>
